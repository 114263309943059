// Import libs
import React from "react";

// Import shared components
import {
  Container,
  ContactForm,
  Heading
} from "@windish/shared";

// Component
function Contact() {

  // Write the final component code to DOM
  return (
    <Container id="contact">
      <Heading as="h3" size="xl">Get in touch</Heading>
      <ContactForm
        callLink="https://calendly.com/windish/contact"
        emailAddress="hello@vindis.io"
      />
    </Container>
  );

}

// Export the component for use
export default Contact;

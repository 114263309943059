// Import libs
import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

// Import common components
import {
	Container,
	Heading,
	PostCard,
	PostCover,
	PostCardGroup,
	PostLock
} from "@windish/shared";

// Import local components
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Contact from "../components/Contact";

// Import template-specific CSS
import "./index.css"

// Post
export default function Template({ data: { mdx, allMdx } }) {

	const postData = mdx;
	const postsData = allMdx.edges;

	// Check if the post is locked
	function PostBody() {
		if (postData.frontmatter.locked === true) {
			return (
				<PostLock />
			);
		}
		else {
			return (
				<MDXRenderer localImages={postData.frontmatter.embedded_images}>
					{postData.body}
				</MDXRenderer>
			)
		}
	}

	// Render post
	return (
		<Layout pageTitle={"Case study"}>
			<article className="article-container">
				<PostCover
					image={postData.frontmatter.cover_image.childImageSharp.gatsbyImageData}
					imageAlt={postData.frontmatter.cover_image_alt}
					imageCaption={postData.frontmatter.cover_image_caption}
					imageCredits={postData.frontmatter.cover_image_credits}
					heading={postData.frontmatter.heading}
					subheading={postData.frontmatter.subheading}
					client={postData.frontmatter.client}
					employer={postData.frontmatter.employer}
					area={postData.frontmatter.area}
					year={postData.frontmatter.year}
					contributions={postData.frontmatter.contributions}
					draft={postData.frontmatter.draft}
				/>
				<PostBody />
			</article>
			<Container>
				<Heading as="h3" size="xl">More success stories</Heading>
				<PostCardGroup>
					{postsData.map(edge => (
						<PostCard
							key={edge.node.id}
							coverImage={edge.node.frontmatter.cover_image.childImageSharp.gatsbyImageData}
							coverImageAlt={edge.node.frontmatter.cover_image_alt}
							client={edge.node.frontmatter.client}
							employer={edge.node.frontmatter.employer}
							heading={edge.node.frontmatter.heading}
							slug={edge.node.slug}
							draft={edge.node.frontmatter.draft}
							locked={edge.node.frontmatter.locked}
						/>
					))}
				</PostCardGroup>
			</Container>
			<Contact />
		</Layout>
	);
}

// Post metadata
export const Head = ({ data: { mdx } }) => (
	<Seo
		title={mdx.frontmatter.client + ": " + mdx.frontmatter.heading}
		description={mdx.frontmatter.subheading}
		image={mdx.frontmatter.cover_image.childImageSharp.resize}
	>
		<script type="application/ld+json">
			{`
        {
					"@context": "https://schema.org",
					"@type": "BlogPosting",
					"mainEntityOfPage": "True",
					"url": "https://vindis.io/${mdx.slug}",
					"image": "https://vindis.io${mdx.frontmatter.cover_image.childImageSharp.resize.src}",
					"headline": "${mdx.frontmatter.heading}",
					"description": "${mdx.frontmatter.subheading}",
					"wordcount": "${mdx.wordCount.words}",				
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
 				}
			`}
		</script>
	</Seo>
)

// Post(s) data
export const postQuery = graphql`
  query ($slug: String!) {
		mdx(
			fields: {
				slug: { eq: $slug }
			}
		){
			id
			slug
      frontmatter {
				cover_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
						resize(width: 1200) {
              src
              height
              width
						}
          }
        }
				cover_image_alt
				cover_image_caption
				cover_image_credits
        heading
        subheading
        client
				employer
        area
        year
        contributions
				employer
				draft
				locked
				embedded_images {
					childImageSharp {
            gatsbyImageData
          }
				}
      }
			body
			wordCount {
				words
			}
    }
		allMdx(
			filter: {
				published: { eq: true },
				fields: {
					slug: { ne: $slug }
				}
			},
			sort: {
				order: DESC,
				fields: [frontmatter___year]
			}
		){
			edges {
				node {
					id
          slug
          frontmatter {
            cover_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            cover_image_alt
            heading
            client
						employer
            year
						draft
						locked
          }
				}
			}
		}
  }
`